<template>
    <el-dialog title="提示" :visible.sync="add_template" width="40%" :before-close="handleClose"
        :modal-append-to-body="toBody">
        <div class="add-template-wrapper">
            <el-radio-group v-model="radioType" @change="changeTemplateRadio">
            <div class="add-template" v-for="(item, index) of templateAddList" :key="index" >
                <template v-if="item.id == '1'">
                    <div class="template-wrapper">
                        <div class="template-header"></div>
                        <div class="template-main">
                            <span class="template-bar"></span>
                            <span class="template-body"></span>
                        </div>
                    </div>
                </template>
                <template v-if="item.id == '2'">
                    <div class="template-wrapper1">
                        <div class="template-header"></div>
                        <div class="template-main1"></div>
                    </div>
                </template>
                <div class="template-title">
                    <el-radio :style="{fontSize: '16px' }" :label="item.id">{{item.title}}</el-radio>
                </div>
            </div>
            </el-radio-group>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible(false)">取 消</el-button>
            <el-button type="primary" @click="dialogVisible(true)">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
    export default {
        name: "AddTemplate",
        props: {
            addTemplateType: {
                type: Boolean
            },
            templateAddList: {
                type: Array
            },
            radio: {
                type: String
            },
            
        },
        data() {
            return {
                toBody: false,
                radioType: '',
                add_template: false,
                
            }
        },
        methods: {
            radio_change () {
                this.radioType = this.radio;
            },
            add_template_change () {
                
                this.add_template = this.addTemplateType;
            },
            changeTemplateRadio (data) {
                this.$emit('changeTemplateRadio', data)
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        this.$emit('dialogVisibleHandler', {radioType: this.radioType, type: false})
                        done();
                    })
                    .catch(_ => {});
            },
            dialogVisible(type) {
                switch (type) {
                    case true:
                        this.$emit('dialogVisibleHandler', {radioType: this.radioType, type: true})
                        break;
                    case false:
                        this.$emit('dialogVisibleHandler', {radioType: this.radioType, type: false})
                        break;
                    default :
                        break;        
                }
            }
        },
        watch: {
            'radio': {
                handler (newVal) {
                    this.radio_change();
                },
                deep: true,
                immediate: true
            },
            'addTemplateType': {
                handler (newVal) {
                    this.add_template_change();
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>

<style scoped>

    .add-template-wrapper {
        overflow: hidden;  
        padding: 0 50px;
    }

    .add-template {
        float: left;
        
    }

    .template-wrapper {
        width: 210px;
        height: 115px;
        box-sizing: border-box;
        border-radius: 8px;
        overflow: hidden;    
        margin-right: 50px;
    }

    .template-title {
        padding: 35px;
    }

    .template-header {
        width: 206px;
        height: 50px;
        padding: 10px;
        background-color: rgb(42, 144, 221);
    }

    .template-main {
        width: 100%;
        height: 65px;
    }

    .template-bar {
        width: 45%;
        height: 100%;
        display: inline-block;
        background-color: rgb(217, 236, 255);
    }

    .template-body {
        width: 55%;
        height: 100%;
        display: inline-block;
        background-color: rgb(236, 245, 255);
    }

    .template-wrapper1 {
        width: 210px;
        height: 115px;
        box-sizing: border-box;
        border-radius: 8px;
        overflow: hidden;    
        margin-right: 50px;
    }

    .template-main1 {
        width: 100%;
        height: 65px;
        background-color: rgb(236, 245, 255);
    }
    
</style>