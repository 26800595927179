<template>
    <div>
        <div class="color-picker">
            <h4 style="clear:both;">{{ data.title }}</h4>
            <div class="pick-option">  
                <colorPicker v-model="data.colorValue" @change="changeColor(data)" />
                <el-input 
                    v-model="data.colorValue"
                    @blur="changeColor(data)"
                ></el-input>
                <div>
                    <el-button 
                        class="color-reset" 
                        :style="{ 'backgroundColor': '#489DF2' }" 
                        round 
                        @click="clickResetHandler(data)"
                    >重置</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: "ThemeColorPicker",
        props: {
            data: {
                type: Object
            }
        },
        data () {
            return {
                colorValue: ''
            }
        },
        computed: {
        },
        methods: {
            clickResetHandler (data) {
                this.$eventDispatch('resetColor', data)    
            },
            changeColor (data) {
                this.$eventDispatch('changeColor', data);
                // switch (data.id) {
                //     case '1':
                //     case '2':
                //     case '4':    
                //         
                //         break;
                //     case '3':
                //         this.$eventDispatch('changeBtnColor', data);
                //         break;   
                    

                //         // this.$eventDispatch('changeTextColor', data);
                //         break;        
                //     default:
                //         break;        
                // }
            }
        }

    }
</script>
<style lang="scss" scoped>
    h4 {
        padding: 20px 0;
        color: #1f2f3d;
    }

    .color-picker {
        width: 100%;

    }

    .color-picker /deep/ .m-colorPicker .colorBtn {
        width: 32px;
        height: 32px;
        margin-right: 5px;
    }

    .color-reset {
        margin-left: 10px;
        color: #ffffff;
    }
    
    .pick-option {
        display: flex;
        justify-content: flex-start;
    }
</style>