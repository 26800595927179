<template>
    <div class="theme-color-view">
        <div class="topics" v-if="showType">
            <div class="topics-lf">
                <h4 class="lf-title">主题</h4>
                <template-wrapper
                    v-for="(item,idx) of template_list"
                    :key="idx"
                    :data="item"
                    :index="idx"
                    :maxLength="template_list.length"
                ></template-wrapper>
            </div>
            <div class="topics-rg">
                <theme-color-picker
                    v-for="(item) of templateTitle"
                    :key="item.id"
                    :data="item"
                ></theme-color-picker>
            </div>
        </div>
    </div>
</template>

<script>
    import TemplateWrapper from './Template/index.vue';
    import ButtonWrapper from './ButtonWrapper.vue';
    import ThemeColorPicker from './ThemeColorPicker.vue';

    export default {
        name: "ThemeColorView",
        components: {
            TemplateWrapper,
            ThemeColorPicker,
            ButtonWrapper
        },
        props: {
            templateList: {
                type: Array
            },
            templateTitle: {
                type: Array
            },
            radio: {
                type: String
            },
        },
        data () {
            return {
                template_list: [],
                template_title: [],
                showType: false
            }
        },
        methods: {
            init () {

                this.template_list = [];
                this.templateList.forEach((c, i) => {
                    this.template_list.push(c);
                });

                this.template_title = [];
                this.templateTitle.forEach((c, i) => {
                    this.template_title.push(c);
                });
                this.showType = true;
            },

        },
        watch: {
            templateList: {
                handler (newVal) {
                    this.init ();
                },
                deep: true,
                immediate: true
            },
        }

    }
</script>

<style scoped>

    h4 {
        padding: 20px 0;
        color: #1f2f3d;
    }

    .topics {
        overflow: hidden;
        margin-bottom: 30px;
    }

    .topics .topics-lf {
        width: 70%;
        float: left;
    }

    .topics .topics-rg {
        width: 30%;
        float: left;
        box-sizing: border-box;
        padding: 0 20px 30px 20px;
        background-color: #f5f7fa;
        border-radius: 4px;
    }

    .btn-wrapper {
        position: relative;
        height: 220px;
    }

    .btn-wrapper .btn-left {
        width: 70%;
        float: left;
        display: flex;
        flex-wrap: wrap;
    }

    .btn-wrapper .btn-right {
        width: 30%;
        float: left;
        box-sizing: border-box;
        padding: 0 20px 30px 20px;
        background-color: #f5f7fa;
        border-radius: 4px;
    }
    .lf-title {
        margin-top: -10px;
    }

</style>
