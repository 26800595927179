<template>
    <div>
        <div 
        :style="{'backgroundColor': data.colorValue, 'borderColor': data.colorValue}" 
        :class="['end-sty',{current: data.sel}]"
        @click="changeBtnSty(data)"
    >{{ data.title}}</div>
    <p class="btn-color">{{ data.colorValue }}</p>
    </div>
</template>
<script>
    export default {
        name: 'ButtonWrapper',
        props: {
            data: {
                type: Object
            }
        },
        data () {
            return {
                _data: {}
            }
        },
        methods: {
            init () {
                Object.keys(this.data).forEach((c, i) => {
                    this._data[c] = this.data[c];
                });
            },
            changeBtnSty (data) {
                this.$eventDispatch('changeBtnSty', data)
            }
        },
        watch: {
            'data': {
                handler () {
                    this.init();
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .end-sty {
        width: 98px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 4px;
        color: #ffffff;
        float: left;
        margin-right: 30px;
        align-self: center;
        margin-bottom: 5px;
        &.current {
            border: 5px solid;
        }
    }

    .end-sty:hover {
        color: #ffffff;
    }

    .btn-color {
        color: #666666;
        text-align: left;
        
    }
    
</style>