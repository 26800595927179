<template>
    <div :style="{ 'border-color': index < 3 ? data.headerColor : '#fff'}" :class="['template-wrap',{current: data.sel }]" @click="changeTemplateColor(data)">
        <div>
        <template v-if="data.type !== ''">
            <template v-if="data.type === 'twoColumns'">
                <div class="template-header" :style="{ 'background-color': data.headerColor}">
                    <span class="iconfont icon-delete" v-if="data.del" @click="delTemplate(data)"></span>
                </div>
                <div class="template-other">
                    <!-- 'color': data.textColor -->
                    <span class="template-left" :style="{ 'background-color': data.barColor}">
                        <div class="menu-item-theme" :style="{ 'background-color': data.barSelectedColor}">一级菜单</div>
                        <div class="menu-item-theme">一级菜单</div>
                        <div class="menu-list" :style="{'background-color': data.barPullDownColor}">
                            <p class="menu-folded menu-folded-sel">二级菜单</p>
                            <p class="menu-folded">二级菜单</p>
                        </div>
                    </span>
                    <span class="template-right" :style="{ 'background-color': data.boardColor }"></span>
                </div>
            </template>
            <template v-if="data.type === 'enterprise'">
                <div class="template-header" :style="{ 'background-color': data.headerColor}">
                    <!-- {{data.headerColor}}
                    <p class="header-text-right" :style="{ 'color': data.textColor}">{{ data.textColor }}</p>
                     -->
                    <span class="iconfont icon-delete" v-if="data.del" @click="delTemplate(data)"></span>
                    <span class="main-menu">一级菜单</span>
                    <span class="main-menu">一级菜单</span>
                    <span class="main-menu">一级菜单</span>
                </div>
                <div class="template-other">
                    <!-- 'color': data.textColor -->
                    <span class="template-left" :style="{ 'background-color': data.barColor,}">
                        <div class="menu-item-theme" :style="{ 'background-color': data.barSelectedColor}">二级菜单</div>
                        <div class="menu-item-theme">二级菜单</div>
                        <div class="menu-list" :style="{'background-color': data.barPullDownColor}">
                            <p class="menu-folded menu-folded-sel">三级菜单</p>
                            <p class="menu-folded">三级菜单</p>
                        </div>
                    </span>
                    <span class="template-right" :style="{ 'background-color': data.boardColor }"></span>
                </div>
            </template>
        </template>
        <template v-else-if="maxLength <= 3">
            <div class="add">
                <span class="iconfont icon-plus"></span>
            </div>
        </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TemplateWrapper',
        props: {
            data: {
                type: Object
            },
            index: {
                type: Number
            },
            maxLength: {
                type: Number
            }
        },
        methods: {
            changeTemplateColor (item) {
                if (this.index >= 3) return;
                this.$eventDispatch('changeTemplateColor', item)
            },
            delTemplate (data) {
                this.$eventDispatch('delTemplate',data)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .template-wrap {
        width: 402px;
        height: 228px;
        float: left;
        margin-right: 30px;
        border-radius: 8px;
        overflow: hidden;
        box-sizing: border-box;
        border: 1px solid;
        margin-bottom: 30px;
        position: relative;
        &.current {
           border: 5px solid;
        }
    }

    .template-wrap .template-header {
        width: 100%;
        height: 54px;
        color: #fff;
        box-sizing: border-box;
        padding: 10px ;
    }

    .template-wrap .template-header .main-menu {
        display: inline-block;
        line-height: 34px;
        padding-right: 20px;
    }

    .template-wrap .template-other {
        width: 100%;
        height: 65px;
    }   

    .template-other .template-left {
        width: 150px;
        height: 172px;
        float: left;
        color: #ffffff;
        box-sizing: border-box;
        padding: 10px;
        font-size: 14px;
        box-sizing: border-box;
    }

    .template-other .template-left .menu-item-theme {
        width: 100%;
        height: 36px;
        line-height: 36px;
        padding-left: 10px;
        font-size: 16px;
        font-weight: 500;
    }
    .template-other .template-left .menu-list {
        width: 100%;
    }

    .template-other .template-left .menu-folded {
        height: 36px;
        line-height: 36px;
        padding-left: 40px;
        font-size: 16px;
        font-weight: 500;
    }



    .template-other .template-right {
        width: 55%;
        height: 65px;
        float: left;
    }

    .add {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20% auto;
        box-sizing: border-box;
    }

    .iconfont {
        font-size: 40px;
        color: #999999;
    }

    .header-text-right {
        font-size: 12px;
    }

    .icon-delete {
        position: absolute;
        top: 10px;
        right: 5px;
        font-size: 24px;
    }
</style>