<template>
    <div class="center-main">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-wrapper">
                <theme-color-view
                    :templateList="template_list"
                    :templateTitle="templateTitle"
                    @changeTemplateColor="changeTemplateColor"
                    @clickResetHandler="clickResetHandler"
                    @changeColor="changeColor"
                    @clickResetBtn="clickResetBtn"
                    @changeBtnColor="changeBtnColor"
                    @changeBtnSty="changeBtnSty"
                    @delTemplate="delTemplate"
                    @resetColor="resetColor"
                    ></theme-color-view>
                <add-template
                    :addTemplateType="addTemplateType" :templateAddList="templateAddList" :radio="radio"
                    @dialogVisibleHandler="dialogVisibleHandler" @changeTemplateRadio="changeTemplateRadio">
                </add-template>
                <div :style="{'margin': '30px', 'textAlign': 'center'}">
                    <xk-button type="primary" @click="add">提交</xk-button>
                </div>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
    import {
        // 功能组件
        Error,
        Loading,
    } from 'common-local';
    // view视图组件
    import ThemeColorView from './ThemeColor/index.vue';
    import AddTemplate from './ThemeColor/AddTemplate.vue';
    // 逻辑运算
    import filterTemplate from '@/operations/themeColor.js';

    import { ThemeColorModel } from '@/models/ThemeColor.js'
    // Vuex
    import {
        mapActions,
        mapState
    } from 'vuex';
    // data 数据
    import themeColor from '@/assets/data/themeColor.js';
    export default {
        name: "ThemeColorScrollWrapper",
        components: {
            ThemeColorView,
            Error,
            Loading,
            AddTemplate
        },
        data() {
            return {
                // 功能组件
                errorShow: false,
                loadingShow: true,
                // 模版数据
                template_list: [],

                // 新增主题
                addTemplateType: false,
                // 新增主题模版
                templateAddList: [{
                        title: '两栏布局',
                        id: '1',
                    },
                    {
                        title: '企业站布局',
                        id: '2',
                    }
                ],

                // 新增默认选中
                radio: "1",


                // 模版调色按钮
                templateTitle: [
                    {
                        id: '1',
                        title: '头部颜色',
                        colorValue: '',
                        key: 'headerColor',
                        show: true
                    },
                    {
                        id: '2',
                        title: '导航栏颜色',
                        colorValue: '',
                        key: 'barColor',
                        show: true
                    },
                    {
                        id: '3',
                        title: '导航菜单选中颜色',
                        colorValue: '',
                        key: 'barSelectedColor',
                        show: true
                    },
                    {
                        id: '4',
                        title: '多级菜单下拉背景颜色',
                        colorValue: '',
                        key: 'barPullDownColor',
                        show: true
                    },
                ],
                // select 按钮颜色
                btnColor: '#ffffff',
            }
        },
        computed: {
            ...mapState({
                templateList: state => state.templateInfo,
                userInfo: state => state
            })
        },
        created() {
            this.init();
        },
        methods: {
            ...mapActions(
                ['setTemplateStyle',]
            ),
            init() {
                // templatelist vuex 赋值
                this.template_list = JSON.parse(JSON.stringify(this.templateList));
                // // templateBtn 赋值
                // this.templateBtnTitle = themeColor.btnSel;

                // 1.初始化颜色pink。 2.深复制模版数组, 重置颜色时使用
                filterTemplate.init(this.template_list, this.templateTitle);

                this.loadingShow = false;
            },
            // 重置
            resetColor (data) {
                let tmpData = this.template_list.filter((item) => {
                    return item.sel;
                })[0];

                this.templateTitle.forEach((item) => {
                    if (item.id == data.id) {
                        item.colorValue = tmpData[item['key']]
                    }
                })
            },
            changeTemplateColor(data) {
                const _this = this;
                filterTemplate.filterTemplate(data, _this)
            },
            clickResetHandler(data) {
            },
            // 更改模版颜色
            changeColor(data) {
                filterTemplate.changeColor(data, this.template_list, this.templateTitle);
            },
            // 弹出框
            // 确定选择布局方式
            dialogVisibleHandler(data) {
                const _this = this;
                filterTemplate.addTemplate(data, _this);
                this.addTemplateType = false;
                this.radio = '1';
                //恢复默认值

            },
            changeTemplateRadio(data) {
                this.radio = data;
            },

            /*
             * 按钮
             */
            clickResetBtn() {

            },
            changeBtnColor(data) {
            },
            // 删除选中模版
            delTemplate(data) {
                this.$confirm('确认删除模版？')
                    .then(_ => {
                        filterTemplate.delTemplate(data, this);
                        done();
                    })
                    .catch(_ => {});
            },
            // change btn sel
            changeBtnSty(data) {
                filterTemplate.changeBtnSty(data, this)
            },
            add() {
                // vuex写入模版数组
                this.setTemplateStyle(this.template_list);
                // vuex 按钮

                let req = {
                    id: this.userInfo.userId,
                    templateInfo: JSON.stringify(this.template_list)
                }
                const themeColorModel = new ThemeColorModel();
                themeColorModel.changeThemeColor(req).then((res) => {
                    if (res.data && res.data.code == '200') {
                        this.$message.success(res.data.msg)
                        // 触发选择按钮
                        filterTemplate.changeTemplateStyle(this.template_list, this)
                    } else if (res.data && res.data.msg) {

                    }
                })

            },
        },
    }
</script>
<style scoped>
    .center-main {
        height: calc(100vh - 128px);
        overflow: auto;
        border-radius: 6px;
        margin: 10px ;
        background-color: #fff;
    }
</style>
